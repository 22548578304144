<template>
  <div class="assets-revert-detail">
    <div class="header">
      <div class="infomation"
           v-if="$route.params.id">
        <van-cell title="出库单号"
                  :value="detail.lendingNumber" />
        <van-cell title="单位"
                  :value="detail.organizationName" />
        <van-cell title="借用人"
                  :value="detail.applicantName" />
        <van-cell title="借用日期"
                  :value="detail.lendingStartDate" />
        <van-cell title="预计归还日期"
                  :value="detail.lendingEndDate" />
        <van-cell title="存放位置"
                  :value="detail.storagePlace" />
        <van-cell title="接收人"
                  :value="detail.pickName" />
        <van-cell title="联系电话"
                  :value="detail.pickMobileNumber" />
        <van-cell title="备注"
                  :value="detail.remark" />
      </div>
    </div>
    <div class="main">
      <div class="detail">
        <div class="list">
          <div class="list-item"
               v-for="item in detail.items"
               :key="item.id"
               @click="itemClick(item)">
            <item-card :title="item.code">
              <template #header>
                <div class="header">
                  <div class="h-title">{{ item.code }}</div>
                  <div class="pick-action"
                       @click.stop="revertAction(item)">
                    <van-icon name="revoke" />
                  </div>
                </div>
              </template>
              <div class="basic">
                <div class="fields">
                  <div class="field">
                    <span class="field-name">资产名称:</span>
                    <span class="field-value">{{ item.name }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">品牌名称:</span>
                    <span class="field-value">{{ item.brandName }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">规格型号:</span>
                    <span class="field-value">{{ item.specifications }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">资产条码:</span>
                    <span class="field-value">{{ item.barcode }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">计量单位:</span>
                    <span class="field-value">{{ item.measuringUnit }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">生产厂商:</span>
                    <span class="field-value">{{ item.manufacturer }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">损坏情况:</span>
                    <span class="field-value">{{ item.status | parseStatus }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">归还时间:</span>
                    <span class="field-value">{{ item.giveBackTime }}</span>
                  </div>
                </div>
              </div>
            </item-card>
          </div>
        </div>
      </div>

      <!-- 状态选择弹框 -->
      <van-popup v-model="showStatus"
                 round
                 position="bottom">
        <custom-popup title="货位"
                      :columns="statusColumns"
                      valueKey='label'
                      @cancel="showStatus = false"
                      @confirm="statusConfirm"></custom-popup>
      </van-popup>
    </div>

    <div class="footer">
      <van-button class="btn"
                  round
                  block
                  type="info"
                  native-type="button"
                  :disabled="!isRevert"
                  @click.stop="revertAction">全部归还</van-button>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/assets/assetsRevertDetail.less";
import { SUCCESS_CODE } from "@/apis/notification";
import { find } from "lodash-es";
import customPopup from "@/components/customPopup.vue";
export default {
  components: { customPopup },
  name: "AssetsRevertDetail",
  data() {
    return {
      detail: {},
      showStatus: false,
      statusColumns: [
        { label: "正常", value: "ENABLE" },
        { label: "报损", value: "DAMAGED" },
        { label: "报修", value: "REPAIR" },
        { label: "报废", value: "DISCARDED" }
      ]
    };
  },
  computed: {
    warehouseComputed() {
      if (!this.warehouseColumns.length) return "";
      let item = find(this.warehouseColumns, item => item.id == this.detail.warehouseId);
      return item ? item.name : "";
    },
    isRevert() {
      return this.detail.items?.some(item => !item.giveBack);
    }
  },
  methods: {
    putAssetLendingGiveBack(ids) {
      let params = {
        ids
      };
      return this.$api.putAssetLendingGiveBack({
        params,
        placeholder: {
          id: this.$route.params.id
        }
      });
    },
    putAssetsStatus(ids, status) {
      let params = {
        ids,
        status
      };
      return this.$api.putAssetsStatus({
        params
      });
    },
    putAssetLendingStatus() {
      let params = {
        ids: [this.$route.params.id],
        status: "GIVE_BACK"
      };
      return this.$api.putAssetLendingStatus({ params });
    },
    async getAssetsRevertDetail() {
      let ret = await this.$api.getAssetLendingsId({
        placeholder: {
          id: this.$route.params.id
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.detail = ret.data;
    },
    itemClick(item) {
      this.curItem = item;
      this.showStatus = true;
    },
    async revert(arg) {
      if (!arg?.id) {
        //全部归还
        let filterData = this.detail.items.filter(item => !item.giveBack);
        let ids = filterData.map(item => item.id);
        let ret = await this.putAssetLendingGiveBack(ids);
        if (ret.code != SUCCESS_CODE) return;
        filterData.forEach(item => {
          item.giveBackTime = ret.data;
          this.putAssetsStatus([item.assetId], item.status);
        });
        this.putAssetLendingStatus();
      } else {
        //单个归还
        let ret = await this.putAssetLendingGiveBack([arg.id]);
        if (ret.code != SUCCESS_CODE) return;
        arg.giveBackTime = ret.data;
        let ids = [arg.assetId];
        this.putAssetsStatus(ids, arg.status);
        let result = this.detail.items.every(item => item.giveBackTime);
        if (result) {
          this.putAssetLendingStatus();
        }
      }
    },
    revertAction(arg) {
      if (arg?.giveBack) return;
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定归还?"
        })
        .then(async () => {
          this.revert(arg);
        })
        .catch(() => {});
    },
    statusConfirm(arg) {
      this.curItem.status = arg.value;
      this.showStatus = false;
    }
  },
  filters: {
    parseStatus(arg) {
      let obj = {
        ENABLE: "正常",
        DAMAGED: "报损",
        REPAIR: "报修",
        DISCARDED: "报废"
      };
      return obj[arg] ?? "";
    }
  },
  created() {
    if (this.$route.params.id) {
      this.getAssetsRevertDetail();
    }
  }
};
</script>
